<template>
    <b-button rounded :id="$props.id"
              :class="$props.class"
              tag="button" :type="'is-'+$props.type"
              @click="$emit('click')" v-html="$props.text" ></b-button>
</template>
<script>
  export default {
    name: 'ButtonComponent',
    props: ['text', 'type', 'id']
  }
</script>
<style lang="scss" scoped>

</style>