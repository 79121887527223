<template>
  <div class="home_view">
    <BannerComponent
        title="Projects and Innovation for technologies."
        subtitle="I'm <b>edwin ariza</b>, engineer helping to plan and transform ideas into simple and cool tech solutions. <i>Books, articles, formats, videos and more</i>."
        :image="require('@/assets/consultant_2.svg')"
        showButtons="true"
        background="#fefefe"
        color="#222222"
        style="z-index:200;"
        buttonName="Contract me"
        @open-modal-contact="$emit('open-modal-contact')"
    />
    <!--<SnackbarComponent />-->
    <!--<FloatMessageComponent
        lang="EN"
        title="Do you want to formulate an innovation project?"
        content="I can help you to find your way to improve and acelerate your idea to transform your business starting with a project formulation to consolidate your innovation."
        type="primary"0047AB
        class="is-hidden-touch"
        href="/formulator"
    />-->
    <!--<FloatMessageComponent
        title="New release!"
        content="Our new version of <a href='https://wydealab.com'>WydeaLab</a> is ready to improve your innovation process. <br><br>We create a software to simplify the idea management process for your organization"
        type="primary"
        class="is-hidden-touch"
    />-->
    <!--<b-container class="is-hidden-touch" style="align-items: center">
      <b-image
        :src="require('@/assets/topper_1.svg')"
        alt="Topper"
        class="is-center"
        style="width: 60%"
      >
      </b-image>
    </b-container>-->
    <StatsComponent style="margin-top:-1px" background="#964063" :stats="stats" />
    <NavLanding class="is-hidden-touch" background="#222222" />
    <IamComponent />
    <StepsComponent />
    <PostsComponent />
    <CertificationsComponent />
    <!--<BriefComponent
        title="Startups"
        subtitle="Do you need technological and research services?"
        content="Our startups provide different solutions to create new products and services to improve process and create cool context experiences, selecting the better way to scale up."
        @open-modal-contact="$emit('open-modal-contact')"
    />-->
    <!-- STARTUPS -->
    <!--<SystemicoBannerComponent />-->
    <!--<WydeaLabBannerComponent />-->
    <!--<PostsScrapidtComponent />-->
    <!--<JumichicaBannerComponent />-->
    <ExperienceComponent />
    <!--<PostsGithubComponent />-->
    <section class="mskills">
      <div class="mskills-content">
        <div class="columns m-0 p-0">
          <div class="column">
            <SkillsComponent />
          </div>
          <div class="column">
            <TipComponent />
          </div>
        </div>
      </div>
    </section>
    <!-- SERVICES-->

    <FaqComponent />
    <ProductsOptionsComponent id="pricing" />
  </div>
</template>
<script>
import BannerComponent from "../../../edwinariza.ui/components/Banner/BannerComponent.vue";
import StatsComponent from "../../../edwinariza.ui/components/Stats/StatsComponent.vue";
import StepsComponent from "@/components/Steps/StepsComponent.vue";
import PostsComponent from "@/components/Posts/PostsComponent.vue";
import FaqComponent from "@/components/Faq/FaqComponent.vue";
import SkillsComponent from "@/components/Skills/SkillsComponent.vue";
import TipComponent from "@/components/Tips/TipComponent.vue";
//import SystemicoBannerComponent from "@/components/Services/SystemicoBannerComponent.vue";
//import WydeaLabBannerComponent from "@/components/Services/WydeaBannerComponent.vue";
//import JumichicaBannerComponent from "@/components/Services/JumichicaBannerComponent.vue";
import ProductsOptionsComponent from "@/components/Paypal/ProductsOptionsComponent.vue";
//import PostsGithubComponent from "@/components/Posts/PostsGithubComponent.vue";
//import BriefComponent from "@/components/Intro/BriefComponent.vue";
//import PostsScrapidtComponent from "@/components/Posts/PostsScrapidtComponent.vue";
import CertificationsComponent from "../../../edwinariza.ui/components/Content/CertificationsComponent.vue";
import IamComponent from '@/components/Intro/IamComponent.vue';
import NavLanding from "../../../edwinariza.ui/components/NavBar/NavLanding.vue";
import ExperienceComponent from "@/components/Intro/ExperienceComponent.vue";
//import FloatMessageComponent from "@/components/Alerts/FloatMessageComponent.vue";
//import SnackbarComponent from "@/components/SnackBar/SnackBarComponent.vue";

export default {
  name: 'HomeView',
  components:{
    //SnackbarComponent,
    StatsComponent,
    //FloatMessageComponent,
    ExperienceComponent,
    NavLanding,
    CertificationsComponent,
    //BriefComponent,
    //PostsGithubComponent,
    ProductsOptionsComponent,
    //JumichicaBannerComponent,
    //WydeaLabBannerComponent,
    //SystemicoBannerComponent,
    SkillsComponent,
    FaqComponent,
    PostsComponent,
    StepsComponent,
    BannerComponent,
    TipComponent,
    //PostsScrapidtComponent,
    IamComponent
  },
  data(){
    return {
      stats: [
        {
          value: "15+",
          description: "Years of experience"
        },
        {
          value: "50+",
          description: "Projects"
        },
        {
          value: "2",
          description: "Countries"
        },
        {
          value: "50+",
          description: "Clients"
        }
      ]
    }
  },
  metaInfo: {
    title: 'Edwin Ariza: Engineer, helping to transform ideas into simple and cool solutions.',
    // override the parent template and just use the above title only
    titleTemplate: null
  },

}
</script>
<style lang="scss">
  @import "../assets/main.scss";
  *{
    font-family:"Quicksand", sans-serif;
  }
  .home_view{

  }
  .mskills{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .mskills-content{
      max-width: $page_content_width;
      width: 100%;
    }
  }
</style>